
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

  .btn {
    @apply font-bold py-2 px-4 rounded;
  }
  .btn-xs{
    @apply text-xs;
  }
  .btn-blue {
    @apply bg-blue-500;
    color:#ffffff !important;
  }
  .btn-blue:hover {
    @apply bg-blue-700;
  }

.ReactModal__Content {
  border: none !important;
  border-radius: 0.5rem;
  /* padding: 2rem;*/
  max-width: 95%;
  max-height: 95%; 
  margin: auto;
  overflow-y: auto;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index:9999;
}

.status-on {
  filter: invert(32%) sepia(19%) saturate(2110%) hue-rotate(94deg) brightness(93%) contrast(80%);
 /* color:#196F3D; */
}

.status-off {
 filter: invert(32%) sepia(70%) saturate(1566%) hue-rotate(338deg) brightness(78%) contrast(95%);
 /* color:#C0392B; */
}


.status-unknown {
  filter: invert(37%) sepia(18%) saturate(474%) hue-rotate(168deg) brightness(97%) contrast(87%);
  /* color:#566573; */
}

.userprofile-button{
  background-color: #ffffff;
  border-radius: 50% !important;
  border:1px solid #000;
   filter: invert(100%)
}

.userprofile-button img{
  width:40px;
  aspect-ratio: 1;
  padding:8px;
  cursor: pointer;
}

.user-icon{
  background:rgb(249, 80, 80);
  border-radius: 50% !important;
  border:1px solid #000;
  width:50px !important;
  aspect-ratio: 1;
  padding:8px !important;
}

.station-icon-off{
  background:rgb(202, 202, 202);
  border-radius: 50% !important;
  border:1px solid #000;
  width:40px !important;
  aspect-ratio: 1;
  padding:3px !important;
}
.station-icon-pending{
  background:rgb(248, 194, 16);
  border-radius: 50% !important;
  border:1px solid #000;
  width:40px !important;
  aspect-ratio: 1;
  padding:3px !important;
}
.station-icon-on{
  background:rgb(81, 171, 8);
  border-radius: 50% !important;
  border:1px solid #000;
  width:40px !important;
  aspect-ratio: 1;
  padding:3px !important;
}
.station-icon-unknown{
  background:rgb(202, 202, 202);
  border-radius: 50% !important;
  border:1px solid #000;
  width:40px !important;
  aspect-ratio: 1;
  padding:3px !important;
}
.station-icon-unknown-active{
  background:rgb(88, 155, 249);
  border-radius: 50% !important;
  border:1px solid #000;
  width:40px !important;
  aspect-ratio: 1;
  padding:3px !important;
  fill:#ffffff
}

.station-icon .custom-marker {
  background-size: cover;
  width: 30px;
  height: 30px;
}
.pulse-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulse-background {
  width: 30px; /* Size of the pulsing effect */
  height: 30px;
  border-radius: 50%;
  position: absolute;
  animation: pulse 700ms infinite;
}

.pulse-green {
  background-color: rgba(81, 171, 8, 1);
}

.pulse-green::after {
  background-color: rgba(81, 171, 8, 0.3); 
}

.pulse-blue {
  background-color: rgba(88, 155, 249, 1);
}

.pulse-blue::after {
  background-color: rgba(88, 155, 249, 0.3); 
}
.pulse-orange {
  background-color: rgba(248, 194, 16, 1); 
}

.pulse-orange::after {
  background-color: rgba(248, 194, 16, 0.3); 
}

.station-icon-img {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 2; 
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}


